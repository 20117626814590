
import { mapState } from 'vuex';
import CommonIntersectionObserver from '@/components/common/IntersectionObserver.vue';
import InspectionsItemIssuesListItemLine from './ItemIssuesListItemLine.vue';

export default {
  name: "InspectionsItemIssuesListItem",
  components: {
    CommonIntersectionObserver,
    InspectionsItemIssuesListItemLine,
  },
  props: {
    photoId: [ String, Number ],
    issues: Array,
    extendedPhotos: Array,
  },
  data: () => ({
    isVisible: false,
  }),
  computed: {
    ...mapState('cesium', [
      'lastActiveIssueId',
    ]),
    ...mapState('inspections__new', [
      'currentInspection',
    ]),
    isExtended() {
      return this.extendedPhotos.indexOf(this.photoId) > -1;
    },
  },
  methods: {
    toggleGroup() {
      this.$emit('toggle-group', this.photoId);
    },
    toggleCategory(category) {
      this.$emit('toggle-category', category);
    },
    show() {
      this.isVisible = true;
    },
  },
  watch: {
    isExtended(newVal) {
      if (!newVal) {
        this.isVisible = false;
      }
    },
  },
}
