
import { mapState } from 'vuex'
import InspectionsItemCesium from './ItemCesium'
import InspectionsItemCompareView from './ItemCompareView'
import InspectionsItemPhotoMiniView from './ItemPhotoMiniView'
import InspectionsItemPhotosNearClick from './ItemPhotosNearClick'
import InspectionsItemCameras from './ItemCameras'
import InspectionsItemMeasurements from './ItemMeasurements'
import InspectionsItemNotes from './ItemNotes'
import InspectionsItemIssues from './ItemIssues'
import InspectionsItemToolbar from './ItemToolbar'
import Loader from '../Loader'

export default {
  name: 'InspectionsItem',
  components : {
    InspectionsItemCesium,
    InspectionsItemCompareView,
    InspectionsItemPhotoMiniView,
    InspectionsItemPhotosNearClick,
    InspectionsItemCameras,
    InspectionsItemMeasurements,
    InspectionsItemNotes,
    InspectionsItemIssues,
    InspectionsItemToolbar,
    Loader,
  },
  computed: {
    ...mapState('inspections__new', [
      'currentInspection',

      'getInspectionsResult',

      'getInspectionsItemResult',
      'getInspectionsItemIsLoading',
      'getInspectionsItemError',
    ]),
    ...mapState('cesium', [
      'currentView',
      'currentMode',
      'currentTool',
      'activeCameraEntity',
      'photosNearClick',
    ]),
    ...mapState('tags', [
      'getTagsResult',
      'getTagsIsLoading',
      'getTagsError',
    ]),
    ...mapState('measurements', [
      'getMeasurementsResult',
      'getMeasurementsIsLoading',
      'getMeasurementsError',
    ]),
    isLoading() {
      return !this.inspection ||
        this.getInspectionsItemIsLoading;
    },
    isMiniViewActive() {
      return this.currentView === 'mini';
    },
    isCompareViewActive() {
      return this.currentView === 'compare';
    },
    isSidebarActive() {
      return this.photosNearClick || this.currentMode !== null;
    },
    inspection() {
      return this.currentInspection;
    },
  },
  watch: {
    currentInspection(newVal) {
      if (newVal && !this.getInspectionsItemIsLoading) {
        const payload = { id: this.currentInspection.id, isBackground: true };
        this.$store.dispatch('inspections__new/getInspectionPhotos', payload);
        this.$store.dispatch('inspections__new/getInspectionIssues', payload);
        this.$store.dispatch('inspections__new/getInspectionNotes', payload);
        this.$store.dispatch('measurements/getMeasurements', { inspection: this.currentInspection.id });
        if (!this.getTagsResult) {
          this.$store.dispatch('tags/getTags');
        }
      }
    },
    currentView() {
      this.$store.dispatch('cesium/cancelToolAction');
      this.$store.state.cesium.currentTool = null;
    },
    currentMode() {
      this.$store.dispatch('cesium/cancelToolAction');
      this.$store.state.cesium.currentTool = null;
    },
    // currentTool() {
    //   this.$store.dispatch('cesium/cancelToolAction');
    // },
  },
}
