
import { mapState } from 'vuex';

export default {
  name: "InspectionsItemPhotosNearClick",
  computed: {
    ...mapState('inspections__new', [
      'currentInspection',
    ]),
    ...mapState('cesium', [
      'photosNearClick',
    ]),
  },
  methods: {
    close() {
      this.$store.state.cesium.photosNearClick = null;
    },
    selectPhoto(photo) {
      this.$store.dispatch('cesium/cameraFlyTo', {
        type: 'heading-pitch-roll',
        position: photo.position,
        data: photo.hpr,
      });
    },
  },
  watch: {
    photosNearClick: {
      handler (newVal) {
        if (newVal && newVal.length > 0) {
          this.selectPhoto(newVal[0]);
        }
      },
      immediate: true,
    },
  },
}
