

import * as Cesium from 'cesium'
import "cesium/Build/Cesium/Widgets/widgets.css"
import CesiumFunctions from '../../core/cesium/CesiumFunctions'
import Loader from '../Loader'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'InspectionsItemCesium',
  props: {
    isCompareView: {
      type: Boolean,
      default: false,
    },
    givenInspection: {
      type: Object,
      default: null,
    },
  },
  components: {
    Loader,
  },
  data () {
    return {
      isLoadingInternal: true,
      handler: null,
    }
  },
  computed: {
    ...mapState('cesium', [
      'activeShape',
      'activeShapePoints',
      'currentView',
      'currentMode',
      'currentTool',
      'floatingPoint',
      'viewer',
      'lastActiveIssueId',
      'lastActiveIssueBillboard',
    ]),
    ...mapState('inspections__new', [
      'currentInspection',

      'getInspectionIssuesResult',
      'getInspectionIssuesIsLoading',
      'getInspectionIssuesError',

      'getInspectionPhotosResult',
      'getInspectionPhotosIsLoading',
      'getInspectionPhotosError',

      'getInspectionPhotosIsLoading',
    ]),
    ...mapGetters('inspections__new', [
      'photosWithCesiumData',
    ]),
    noCesiumData() {
      return this.$store.getters['inspections__new/noCesiumData'];
    },
    inspection() {
      return (this.isCompareView && this.givenInspection) ? this.givenInspection : this.currentInspection;
    },
    isLoading() {
      return this.isLoadingInternal || this.getInspectionPhotosIsLoading;
    },
    containerId() {
      return this.isCompareView ? 'cesiumCompareContainer' : 'cesiumContainer';
    },
  },
  methods: {
    initCesium() {
      Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJjMjUwYWI3My05MTk3LTQyNDktYmRmMS00MTU2MGUxYmI3ZDIiLCJpZCI6MjU5MjgsInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1ODY4NzA4OTJ9.6WPWSe0-SU4dzAHEaJPpwbFFr-wj8PsZ0JS3EA0mUQg';
      var viewer = new Cesium.Viewer(this.containerId, {
        terrainProvider: Cesium.createWorldTerrain({
          requestVertexNormals : true,
        }),
        geocoder: false,
        sceneModePicker: false,
        navigationHelpButton: false,
        animation: false,
        creditsDisplay: false,
        timeline: false,
        infoBox: false,
        homeButton: false,
        fullscreenButton: false,
        baseLayerPicker: false,
        selectionIndicator: false
      });

      var assetIDs = this.inspection.cesium_id.replace(' ', '').split(',');
      const tilesets = [];
      for (var i = 0; i < assetIDs.length; i++) {
        const tileset = new Cesium.Cesium3DTileset({
          url: Cesium.IonResource.fromAssetId(assetIDs[i]),
          maximumScreenSpaceError: this.inspection.cesium_maxError,
          maximumMemoryUsage: 2048,
          dynamicScreenSpaceError: true,
          skipLevelOfDetail: false,
          foveatedScreenSpaceError: true,
          skipLevels: 2,
          skipScreenSpaceErrorFactor: 1,
          immediatelyLoadDesiredLevelOfDetail: false,
          baseScreenSpaceError: 1024,
          loadSiblings: false,
        });
        tileset.loadProgress.addEventListener((numberOfPendingRequests, numberOfTilesProcessing) => {
            if ((numberOfPendingRequests === 0) && (numberOfTilesProcessing === 0)) {
              if (i === (assetIDs.length)) {
                this.isLoadingInternal = false;
              }
              if (this.inspection.adjust_cameras) {
                const cartographic = Cesium.Cartographic.fromCartesian(tileset.boundingSphere.center);
                const surface = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 0.0);
                const offset = Cesium.Cartesian3.fromRadians(
                  cartographic.longitude + parseFloat(this.inspection.adjust_longitude || 0),
                  cartographic.latitude + parseFloat(this.inspection.adjust_latitude || 0),
                  parseFloat(this.inspection.adjust_altitude || 0),
                );
                const translation = Cesium.Cartesian3.subtract(offset, surface, new Cesium.Cartesian3());
                tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
              }
            return;
          }
        });
        tilesets.push(tileset);
        viewer.scene.primitives.add(tileset);
      }

      viewer.camera.defaultZoomAmount = 10.0;
      viewer.camera.frustum.fov = 1;
      if (this.isCompareView) {
        viewer.camera.percentageChanged = 0.01;

        viewer.scene.screenSpaceCameraController.enableRotate = false;
        viewer.scene.screenSpaceCameraController.enableTranslate = false;
        viewer.scene.screenSpaceCameraController.enableZoom = false;
        viewer.scene.screenSpaceCameraController.enableTilt = false;
        viewer.scene.screenSpaceCameraController.enableLook = false;
      }
      if (tilesets.length > 0) {
        viewer.zoomTo(tilesets[tilesets.length - 1]);
      }

      if (this.isCompareView) {
        viewer.scene.camera = this.viewer.scene.camera;
        this.$store.state.cesium.viewerCompare = viewer
      } else {
        this.$store.state.cesium.viewer = viewer
        this.initHandler();
      }
    },
    initHandler() {
      this.handler = new Cesium.ScreenSpaceEventHandler(this.viewer.canvas);

      // left click
      this.handler.setInputAction(event => {
        if (!this.currentTool) {
          // Show Photo from Camera
          if (this.currentMode === 'cameras') {
            let objectClicked = this.viewer.scene.pick(event.position);
            if (Cesium.defined(objectClicked) && objectClicked?.id?._isCamera) {
              this.$store.dispatch('cesium/flyToCamera', objectClicked.id);
              return;
            }
          }
          // Fly to clicked note
          if (this.currentMode === 'notes') {
            let objectClicked = this.viewer.scene.pick(event.position);
            const prefix = 'note-';
            if (Cesium.defined(objectClicked) && objectClicked.id?.id?.startsWith?.(prefix)) {
                this.$store.commit('cesium/flyToEntity', { entityId: objectClicked.id.id });
            }
          }
          if (this.currentMode === 'measurements') {
            let objectClicked = this.viewer.scene.pick(event.position);
            const prefix = 'measure-';
            if (Cesium.defined(objectClicked) && objectClicked.id?.id?.startsWith?.(prefix)) {
                this.$store.dispatch('cesium/toggleMeasurementItem', objectClicked.id);
            }
          }
          if (this.currentMode === 'issues') {
            // Fly to clicked issue parent
            let objectClicked = this.viewer.scene.pick(event.position);
            const prefix = 'issue-';
            if (Cesium.defined(objectClicked) && objectClicked?.id?.startsWith?.(prefix)) {
              const id = parseInt(objectClicked.id.substring(prefix.length), 10);
              if (this.lastActiveIssueId === id) {
                this.$store.commit('cesium/resetLastIssue');
              } else {
                this.$store.state.cesium.lastActiveIssueId = id;
                this.$store.dispatch('cesium/cameraFlyToIssue', { id });
              }
            }
          }
          return;
        }

        // Measuring modes
        if (this.currentTool?.code.indexOf('measure-') > -1) {
          this.$store.commit('cesium/setSelectedMeasurementId', null);
          const position = this.viewer.scene.pickPosition(event.position);
          if (Cesium.defined(position)) {
            let points = [ ...this.$store.state.cesium.activeShapePoints ];
            if (points.length === 0) {
              this.$store.state.cesium.floatingPoint = CesiumFunctions.drawPoint({ viewer: this.viewer, position });
              points.push(position);
              var dynamicPositions = new Cesium.CallbackProperty(() => {
                if (this.currentTool.type === 'polygon') {
                  return new Cesium.PolygonHierarchy(this.$store.state.cesium.activeShapePoints);
                }
                return this.$store.state.cesium.activeShapePoints;
              }, false);
              this.$store.state.cesium.activeShape = CesiumFunctions.drawShape({
                viewer: this.viewer,
                positions: dynamicPositions,
                type: this.currentTool.type,
                isDraft: true,
              });
            }
            points.push(position);
            this.$store.state.cesium.activeShapePoints = points;
            this.$store.state.cesium.activeShapePointsGeometry = [
              ...this.$store.state.cesium.activeShapePointsGeometry,
              CesiumFunctions.drawPoint({ viewer: this.viewer, position }),
            ];
            if (this.currentTool.type === 'height' && points.length >= 3) {
              this.$store.dispatch('cesium/completeToolAction');
            }
          }
          return;
        }

        // Add note
        if (this.currentTool.code === 'notes-add') {
          let position = this.viewer.scene.pickPosition(event.position);
          if (Cesium.defined(position)) {
            if (this.floatingPoint) {
              this.viewer.entities.remove(this.floatingPoint);
            }
            this.$store.state.cesium.floatingPoint = CesiumFunctions.drawPoint({ viewer: this.viewer, position });
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

      // mouse move
      this.handler.setInputAction(event => {
        if (!this.currentTool) {
          return;
        }

        // Measuring modes
        if (this.currentTool.code.indexOf('measure-') > -1) {
          if (Cesium.defined(this.floatingPoint)) {
            var newPosition = this.viewer.scene.pickPosition(event.endPosition);
            if (Cesium.defined(newPosition)) {
              this.floatingPoint.position.setValue(newPosition);
              this.$store.state.cesium.activeShapePoints = [
                ...this.$store.state.cesium.activeShapePoints.slice(0, this.$store.state.cesium.activeShapePoints.length - 1),
                newPosition,
              ];
            }
          }
        }

      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

      // right click
      this.handler.setInputAction(event => {
        if (!this.currentTool) {
          const position = this.viewer.scene.pickPosition(event.position);

          if (!Cesium.defined(position)) {
            return;
          }

          const photosSearch = () => {

            let closestPhotos = [ ...this.photosWithCesiumData ].map((photo, i) => {
              return {
                intersection: CesiumFunctions.checkImage({
                  clickPosition: position,
                  photoPosition: photo.position,
                  frustum: [ photo.frustum.bL2, photo.frustum.bR2, photo.frustum.tL2, photo.frustum.tR2 ],
                  viewer: this.viewer,
                }),
                photo: photo.id,
                index: i,
              };
            });

            this.$store.state.cesium.photosNearClick = closestPhotos
              .filter(x => !!x.intersection)
              .sort((a, b) => a.intersection - b.intersection)
              .slice(0, 10)
              .map(x => this.photosWithCesiumData[x.index]);
          };

          if (!this.getInspectionPhotosResult || this.getInspectionPhotosResult.length === 0 || this.getInspectionPhotosResult[0].inspection !== this.currentInspection.id) {
            this.$store.dispatch('inspections__new/getInspectionPhotos', {
              id: this.currentInspection.id,
              onSuccess: photosSearch,
            });
          } else {
            photosSearch();
          }
          return;
        }
        if (this.currentTool.type === 'height' && this.activeShapePoints.length <= 2) {
          this.$store.dispatch('cesium/cancelToolAction');
          return;
        }
        this.$store.dispatch('cesium/completeToolAction');
      }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
    },
  },
  mounted() {
    if (this.noCesiumData) {
      this.isLoadingInternal = false;
      return;
    }
    this.initCesium();
  },
}
