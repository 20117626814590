
import { mapState } from 'vuex';
import Loader from '../Loader';
import InspectionsItemIssuesListItem from './ItemIssuesListItem';

export default {
  name: "InspectionsItemIssues",
  components: {
    Loader,
    InspectionsItemIssuesListItem,
  },
  data: () => ({
    // extendedCounter: 0,
    extendedPhotos: [],
  }),
  computed: {
    ...mapState('cesium', [
      'activeIssuesCategories',
      'lastActiveIssueId',
    ]),
    ...mapState('users', [
      'getUsersIsLoading',

      'getCurrentProfileIsLoading',
    ]),
    ...mapState('inspections__new', [
      'currentInspection',

      'getInspectionIssuesResult',
      'getInspectionIssuesIsLoading',
      'getInspectionIssuesError',
    ]),
    isLoading() {
      return this.getInspectionIssuesIsLoading;
    },
    selectedPhoto() {
      if (this.lastActiveIssueId && this.getInspectionIssuesResult) {
        const result = this.getInspectionIssuesResult.find(x => x.id === this.lastActiveIssueId)?.photo;
        return parseInt(result, 10) || null;
      }
      return null;
    },
    baseItems() {
      if (!this.getInspectionIssuesResult) {
        return [];
      }
      if (this.selectedPhoto) {
        const result = this.getInspectionIssuesResult.filter(x => x.photo === this.selectedPhoto);
        return result;
      }
      return this.getInspectionIssuesResult;
    },
    categories() {
      return this.baseItems.reduce((acc, curr) => {
        if (!acc.find(x => x.code === curr.issue_type)) {
          acc.push({
            code: curr.issue_type,
            color: curr.label_color_code,
            isColorLight: curr.is_label_color_light,
          });
        }
        return acc;
      }, []).sort((a, b) => a.code > b.code ? 1 : -1);
    },
    itemsFiltered() {
      if (this.activeIssuesCategories.length === 0) {
        return this.baseItems;
      }
      return this.baseItems.filter(x => this.activeIssuesCategories.indexOf(x.issue_type) > -1);
    },
    photos() {
      return this.itemsFiltered.reduce((acc, curr) => {
        if (acc[curr.photo]) {
          acc[curr.photo].push(curr);
        } else {
          acc[curr.photo] = [ curr ];
        }
        return acc;
      }, {});
    },
    photosAmount() {
      return Object.keys(this.photos).length;
    },
    isAllExtended() {
      return this.extendedPhotos.length === this.photosAmount;
    },
  },
  methods: {
    toggleCategory(categoryCode) {
      if (this.activeIssuesCategories.indexOf(categoryCode) > -1) {
        this.$store.state.cesium.activeIssuesCategories = this.activeIssuesCategories.filter(x => x !== categoryCode);
      } else {
        this.$store.state.cesium.activeIssuesCategories = [ ...this.activeIssuesCategories, categoryCode ];
      }
    },
    resetCategories() {
      this.$store.state.cesium.activeIssuesCategories = [];
    },
    toggleEtendedMode() {
      if (this.isAllExtended) {
        this.extendedPhotos = [];
      } else {
        this.extendedPhotos = Object.keys(this.photos);
      }
    },
    toggleGroup(photo) {
      const photoIndex = this.extendedPhotos.indexOf(photo);
      if (photoIndex > -1) {
        this.extendedPhotos.splice(photoIndex, 1);
      } else {
        this.extendedPhotos.push(photo);
      }
    },
    resetSelectedPhoto() {
      this.$store.commit('cesium/resetLastIssue');
    },
  },
  beforeMount() {
    this.$store.dispatch('cesium/showIssues');
  },
  beforeUnmount() {
    this.$store.dispatch('cesium/hideIssues');
  },
  watch: {
    activeIssuesCategories() {
      this.$store.dispatch('cesium/filterIssues');
    },
  },
}
