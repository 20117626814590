
import { mapState } from 'vuex';

export default {
  name: "InspectionsItemIssuesListItemLine",
  props: {
    issue: Object,
  },
  data: () => ({
    scrollAttempts: 0,
  }),
  computed: {
    ...mapState('cesium', [
      'lastActiveIssueId',
    ]),
  },
  methods: {
    toggleCategory() {
      this.$emit('toggle-category', this.issue.issue_type);
    },
    flyToIssue() {
      if (this.lastActiveIssueId === this.issue.id) {
        this.$store.commit('cesium/resetLastIssue');
        if (this.$store.state.cesium.savedCameraPosition) {
          this.$store.dispatch('cesium/cameraFlyTo', this.$store.state.cesium.savedCameraPosition);
          this.$store.state.cesium.savedCameraPosition = null;
        }
      } else {
        this.$store.state.cesium.lastActiveIssueId = this.issue.id;
        this.$store.dispatch('cesium/cameraFlyToIssue', { issue: this.issue });
      }
    },
    reqursiveScrollToSelected() {
      const ref = this.$refs.selected;
      if (!ref) {
        if (this.scrollAttempts < 10) {
          this.$nextTick(this.reqursiveScrollToSelected);
          this.scrollAttempts++;
        }
        return;
      }
      ref.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
  },
  watch: {
    lastActiveIssueId: {
      immediate: true,
      handler(newVal) {
        if (newVal && this.issue.id === newVal) {
          this.reqursiveScrollToSelected();
        }
      },
    },
  },
}
