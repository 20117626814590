
import { mapState } from 'vuex';

export default {
  name: 'InspectionsItemToolbar',
  computed: {
    ...mapState('cesium', [
      'viewer',
      'currentView',
      'currentMode',
    ]),
    ...mapState('users', [
      'getCurrentProfileResult',
      'getCurrentProfileIsLoading',
      'getCurrentProfileError',

      'getUsersResult',
      'getUsersIsLoading',
      'getUsersError',
    ]),
    ...mapState('inspections__new', [
      'currentInspection',

      'getInspectionNotesResult',
      'getInspectionNotesIsLoading',
      'getInspectionNotesError',

      'getInspectionIssuesResult',
      'getInspectionIssuesIsLoading',
      'getInspectionIssuesError',

      'getInspectionPhotosResult',
      'getInspectionPhotosIsLoading',
      'getInspectionPhotosError',
    ]),
    tools() {
      if (this.$store.getters['inspections__new/noCesiumData']) {
        return [];
      }
      return [
        {
          title: 'Views',
          items: [
            {
              title: 'Mini view',
              code: 'mini',
              type: 'view',
              icon: 'media/icons/duotune/general/gen004.svg',
            },
            {
              title: 'Compare view',
              code: 'compare',
              type: 'view',
              icon: 'media/icons/duotune/general/gen009.svg',
            },
          ],
        },
        {
          title: 'Tools',
          items: [
            {
              title: 'Cameras',
              code: 'cameras',
              type: 'mode',
              icon: 'media/icons/duotune/technology/teh008.svg',
            },
            {
              title: 'Issues',
              code: 'issues',
              type: 'mode',
              icon: 'media/icons/duotune/coding/cod009.svg',
            },
            {
              title: 'Measurements',
              code: 'measurements',
              type: 'mode',
              icon: 'media/icons/duotune/general/gen005.svg',
            },
            {
              title: 'Notes',
              code: 'notes',
              type: 'mode',
              icon: 'media/icons/duotune/general/gen054.svg',
            },
          ],
        },
      ];
    },
  },
  methods: {
    toolClickHandler(tool) {
      if (tool.type === 'view') {
        this.$store.state.cesium.currentView = this.currentView === tool.code ? null : tool.code;
      } else if (tool.type === 'mode') {
        this.$store.state.cesium.currentMode = this.currentMode === tool.code ? null : tool.code;
        if (this.$store.state.cesium.currentMode !== null) {
          this.$store.state.cesium.photosNearClick = null;
        }
      }
    },
  },
}
