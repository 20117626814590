
import { mapState } from 'vuex';

export default {
  name: "InspectionsItemNotesListItem",
  props: {
    note: Object,
  },
  data: () => ({
    isRemoveReady: false,
  }),
  computed: {
    ...mapState('users', [
      'getUsersResult',
      'getUsersIsLoading',
      'getUsersError',
    ]),
    ...mapState('inspections__new', [
      'getInspectionsItemResult',

      'getInspectionNotesResult',
      'getInspectionNotesIsLoading',
      'getInspectionNotesError',

      'removeInspectionNotesItemResult',
      'removeInspectionNotesItemIsLoading',
      'removeInspectionNotesItemError',
    ]),
    isLoading() {
      return this.getInspectionNotesIsLoading || this.getUsersIsLoading;
    },
    user() {
      return this.getUsersResult?.find(x => x.id === this.note.user);
    },
    name() {
      if (!this.user) {
        return '';
      }
      return [this.user.first_name, this.user.last_name].filter(x => !!x).join(' ');
    },
    urgencyClass() {
      const urgency = this.note.urgency.toLowerCase();
      if (urgency === 'low') {
        return 'badge-light-info';
      } else if (urgency === 'medium') {
        return 'badge-light-warning';
      }
      return 'badge-light-danger';
    },
  },
  methods: {
    removeHandler() {
      if (this.isRemoveReady && !this.removeInspectionNotesItemIsLoading) {
        this.$store.dispatch('inspections__new/removeInspectionNotesItem', { id: this.note.id, onSuccess: this.successHandler });
        this.isRemoveReady = false;
      } else {
        this.isRemoveReady = true;
      }
    },
    removeCancelHandler() {
      this.isRemoveReady = false;
    },
    successHandler() {
      this.$store.commit('cesium/deleteNote', { id: this.note.id });
      this.$store.dispatch('inspections__new/getInspectionNotes', { id: this.getInspectionsItemResult.id });
    },
    flyToNote(note) {
      this.$store.commit('cesium/flyToEntity', { entityId: 'note-' + note.id });
    },
  },
}
