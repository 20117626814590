
import { mapState } from 'vuex';
import Loader from '../Loader'

export default {
  name: "InspectionsItemCameras",
  components: {
    Loader,
  },
  data: () => ({
    isImgLoading: false,
  }),
  computed: {
    ...mapState('inspections__new', [
      'currentInspection',

      'getInspectionPhotosIsLoading',
    ]),
    ...mapState('cesium', [
      'activeCameraEntity',
    ]),
    getInspectionPhotosResult() {
      return this.$store.getters['inspections__new/photosWithCesiumData'];
    },
    photo() {
      if (!this.activeCameraEntity || !this.getInspectionPhotosResult) {
        return null;
      }
      const currentPhotoId = parseInt(this.activeCameraEntity.id.split('-')[1], 10);
      return this.getInspectionPhotosResult.find(x => x.id === currentPhotoId) || null;
    },
    isLoading() {
      return this.getInspectionPhotosIsLoading || this.isImgLoading;
    },
  },
  methods: {
    back() {
      this.$store.state.cesium.activeCameraEntity = null;
      if (this.$store.state.cesium.savedCameraPosition) {
        this.$store.dispatch('cesium/cameraFlyTo', this.$store.state.cesium.savedCameraPosition);
        this.$store.state.cesium.savedCameraPosition = null;
      }
    },
    photoLoadHandler() {
      this.isImgLoading = false;
    },
    goToMiniViewer() {
      this.$store.state.cesium.currentView = 'mini';
    },
    setActiveCamera(camera) {
      const cameraEntity = this.$store.state.cesium.viewer.entities.getById('camerasEntity')?._children.find(x => x.id.indexOf(camera.id) > 0);
      if (cameraEntity) {
        this.$store.dispatch('cesium/flyToCamera', cameraEntity);
      } else {
        console.warn('camera is not found');
      }
    },
  },
  beforeMount() {
    const id = this.currentInspection.id;
    if (!this.getInspectionPhotosResult || (this.getInspectionPhotosResult.length > 0 && this.getInspectionPhotosResult[0].inspection !== id)) {
      this.$store.dispatch('inspections__new/getInspectionPhotos', {
        id,
        onSuccess: () => {
          this.$store.dispatch('cesium/showCameras');
        },
      });
    } else {
      this.$store.dispatch('cesium/showCameras');
    }
  },
  beforeUnmount() {
    this.$store.dispatch('cesium/hideCameras');
  },
  watch: {
    photo(newVal) {
      if (newVal) {
        this.isImgLoading = true;
      } else {
        this.isImgLoading = false;
      }
    },
  },
}
