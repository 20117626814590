
import { mapState } from 'vuex';
import Loader from '../Loader'

export default {
  name: "InspectionsItemNotesAdd",
  components: {
    Loader,
  },
  data: () => ({
    content: '',
    urgency: '',
    position: '',
    file: null,
  }),
  computed: {
    ...mapState('cesium', [
      'currentTool',
      'floatingPoint',
    ]),
    ...mapState('users', [
      'getCurrentProfileResult',
      'getCurrentProfileIsLoading',
    ]),
    ...mapState('inspections__new', [
      'currentInspection',

      'getInspectionNotesResult',
      'getInspectionNotesIsLoading',
      'getInspectionNotesError',

      'createInspectionNotesItemResult',
      'createInspectionNotesItemIsLoading',
      'createInspectionNotesItemError',
    ]),
    isLoading() {
      return this.getInspectionNotesIsLoading || this.createInspectionNotesItemIsLoading || this.getCurrentProfileIsLoading;
    },
    isValid() {
      return this.currentInspection && this.content && this.urgency && this.position;
    },
  },
  methods: {
    addFileHandler(e) {
      if (e.target.files.length > 0) {
        this.file = e.target.files[0];
      } else {
        this.file = null;
      }
    },
    onSubmit() {
      if (!this.isValid || this.isLoading) {
        return;
      }
      const payload = new FormData();
      payload.append('content', this.content);
      payload.append('urgency', this.urgency);
      payload.append('position', `${this.position.x}, ${this.position.y}, ${this.position.z}`);
      payload.append('inspection', this.currentInspection.id);
      payload.append('user', this.getCurrentProfileResult.user);
      if (this.file) {
        payload.append('file', this.file);
      }
      this.$store.dispatch('inspections__new/createInspectionNotesItem', {
        data: payload,
        onSuccess: this.successHandler,
      });
    },
    successHandler(data) {
      this.$store.state.inspections__new.getInspectionNotesResult = [ data, ...this.getInspectionNotesResult ];
      this.$store.dispatch('cesium/completeToolAction');
      this.$store.commit('cesium/drawNote', data);
      this.content = '';
      this.urgency = '';
      this.position = '';
      this.file = null;
    },
    setCurrentTool() {
      if (this.$store.state.cesium.currentTool) {
        if (this.$store.state.cesium.currentTool.code === 'measure-height') {
          this.$store.dispatch('cesium/cancelToolAction');
        } else {
          this.$store.dispatch('cesium/completeToolAction');
        }
      }
      this.$store.state.cesium.currentTool = { title: 'Add note', code: 'notes-add', type: 'point', icon: 'media/icons/duotune/general/gen055.svg' };
    },
    back() {
      this.$emit('back');
    },
  },
  mounted() {
    this.setCurrentTool();
  },
  watch: {
    floatingPoint(newVal) {
      if (this.currentTool?.code === 'notes-add') {
        if (newVal) {
          this.position = newVal.position._value;
        } else {
          this.position = '';
        }
      }
    },
  },
}
